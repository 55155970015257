body {
  overflow-x: hidden;
}

.swiper {
  position: relative;
  width: 100%;
  aspect-ratio: 1/1;
  padding: 0 15px 15px 15px;
  overflow: visible;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
}

.swiper-button-next,
.swiper-button-prev {
  background-color: transparent;
  height: 50px;
  width: 50px;
  position: absolute;
  top: calc(50% - 15px);
  z-index: 9;
  font-size: 28px;
}
.swiper-button-prev {
  left: 30px;
}
.swiper-button-next {
  right: 5px;
}
.swiper-pagination-bullet {
  width: 40px;
  height: 40px;
  background-color: red;
}
